.footer-container {
  background-color: rgb(10, 151, 151);
  width: 100%;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  color: #fff;
  padding: 20px 0;
}

/* Centered Logo */
.img_logo {
  background-color: #fff;
  width: 150px;
  height: auto;
  border-radius: 5px;
}

/* Address Column Styling */
.footer-col {
  text-align: center;
  padding-bottom: 30px;
}

/* Contact Icons Alignment */
.contact-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  gap: 10px;
}

/* Social Icons */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.social-icon-link {
  color: #fff;
  font-size: 24px;
}

/* Footer Last Line */
.website-rights {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
}

.nextek {
  color: #fff;
  text-decoration: none;
}
