.hero-container {
    position: relative;
    width: 100%;
    height: auto ; 
    min-height: 200px;
    margin: auto;
    margin-bottom: 1rem;
    position:relative;
    display: flex;
    z-index: 1;
} 
.clean {
   width:100%;
  height: auto; 
}



.link_hero {
  box-shadow: 2px 2px 5px rgb(11, 116, 116);
  position: absolute;
  width: 20%;
  height: 10%;
  bottom:15%;
  left:15%;
  text-align: center;
  text-decoration: none;
  background-color: rgb(10, 151, 151);
  color: aliceblue;
  border-radius:  20px;
  font-size: 1vw;
}
.align-middle {
 
  margin-top:4%;
}


.link_hero:hover {
  background-color: rgb(15, 165, 165);
  color: rgb(158, 238, 234);
}
.link_hero:active {
  background-color: rgb(9, 104, 104);
  color: rgb(219, 235, 245);
}

 
 .allTitle {
  position: absolute;
  top:20%;
  left: 15%;
  text-transform: uppercase;
 }
 .hero-title1 { 
  color: rgb(10, 151, 151);
  font-size:3vw;
  font-weight: 400;
  text-align:left;
  font-family: -apple-system, BlinkMacSystemFont, 
  'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 
  'Open Sans', 'Helvetica Neue', sans-serif;
 
} 
  .hero-title2 {
  color: rgb(252, 255, 251);
  font-size:8px;
  font-family:-apple-system, BlinkMacSystemFont, 
  'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 
  'Open Sans', 'Helvetica Neue', sans-serif;
 
} 

  
 @media (min-width:595px) {
  .allTitle {
    top:20%;
    left: 15%;
   }
   .hero-title1 { 
    font-size:3vw;
  } 
    .hero-title2 {
    font-size:1.5vw;
    
  } 
  
} 
 @media(max-width:594px) {
   .clean-small {
     width: 100%;
     height: auto ;
    background-image: url(../images/clean4.jpg);
    background-position: center;
    background-size:cover;
    background-repeat: no-repeat;
   }
   .clean {
     display:none;
   }
   .allTitle {
    top:15%;
    left: 10%;
   }
   .hero-title1 { 
    font-size:4vw;
  } 
    .hero-title2 {
    font-size:2vw;
  } 
  .link_hero  {
    left: 20%;
    font-size: 2.5vw;
  }
  
 }