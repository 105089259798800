.consultation {
    background-color: #e2948f;
    width: 80%;
    height: auto;
    margin: auto;
    padding-bottom: 3rem;
     padding-top:3rem; 
  }
.contact-name {
   background-color: rgb(244, 248, 255);
  
}
   form {
    width:60%;
    margin: auto;
    margin-bottom: 1rem;
  }
  @media (min-width: 769px) {
    .consultation {
      width: 60%;
      height: auto;
    }
  
     form {
      width:50%;
    }
  }