 .cont-services{
   width: 90%;
   height: auto;
   margin: auto;
    background: none;
    font-family: "Open Sans";
  }
  
  .container{
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }
  
  .cart-container{
    width: 50%;
    padding-top: 10px;
    box-sizing: border-box; 
    
  } 
  
  .cart{
    padding-top: 5px;
    padding-bottom: 10px;
    background: transparent;
    border-radius: 3px;
    box-sizing: border-box;
    display: block;
    color: #000;
    text-decoration: none;
    transition: box-shadow 0.3s, transform 0.3s;
    transform: scale(1);
    
  }
  
  .cart:hover{
    transform: scale(1.05);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    z-index: 10;
    background-color: rgb(10, 151, 151);
    -webkit-transition: background-color 2000ms linear;
    -ms-transition: background-color 2000ms linear;
    transition: background-color 2000ms linear;
  }


  .img-all {
    border: thick double rgb(16, 177, 177);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 30px auto 30px;
    background-color: none;
    background-position: center;
    background-size: cover;
  }
  .cart .image-one{
    background-image: url(../images/1.png);  
  } 

  .cart .image-two{
    background-image: url(../images/2.png); 
  }
  .cart .image-three{
    background-image: url(../images/4.png); 
  }
  .cart .image-four{
    background-image: url(../images/3.png); 
   }
  .cart .image-five{
    background-image: url(../images/5.png); 
   }
  .cart .image-six{
    background-image: url(../images/window1.png); 
    }
  .cart .image-seven{
    background-image: url(../images/kids2.png); 
   }
  .cart .image-eight{
    background-image: url(../images/hall.png); 
   }
  
  .cart .title-services{
    font-size: 20px;
    color: rgb(38, 83, 83);
    margin: auto;
  }
  
  
 @media (max-width:320px){
  .cart .title-services{
    font-size:18px;
  }
 }

  @media only screen and (min-width: 600px) {
    .cart-container{
      width: 50%;
    }
  }
  
  @media only screen and (min-width: 1200px) {
    .cart-container{
      width: 25%;
    }
  }
  
  .carts-circle{
    max-width: 1200px;
    margin: 0 auto;
  }
   
 
   