.logo {
   
    width:100%;
    height:auto;
    background-image: url(../images/cl22.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto;
    padding-bottom: 2rem;
    padding-top: 10%;
    
  }
  @media (max-width:1023px) {
    .logo {
      background-size: 20%;
      margin-bottom: -5rem;
    }
  }
  