.About-container {
  background-color: rgb(240, 240, 232);
  position: relative;
  width: 90%;
  height: auto;
  margin: auto;
  margin-top: 2rem;

  margin-bottom: 5rem;
  color: black;
}

.informationServices-about {
  font-size: 130%;
  font-family: "Playfair Display", serif;
  color: rgb(87, 53, 75);

  letter-spacing: 0.5px;
  line-height: 2rem;
  text-align: justify;
  padding-inline: 2rem;
  padding-top: 2rem;
  text-indent: 2rem;
}
.informationServices-about2 {
  font-size: 130%;
  font-family: "Playfair Display", serif;
  color: rgb(87, 53, 75);

  letter-spacing: 0.5px;
  line-height: 2rem;
  text-align: justify;
  padding-inline: 2rem;
  text-indent: 2rem;
}

.who {
  background-color: rgb(244, 248, 255);
}

.colum-about {
  height: 500px;
}

.colum-img1 {
  width: 50%;
  height: auto;
  min-height: 250px;
  background-image: url(../images/about3.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
