
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "PT Sans", sans-serif; */
 
}

#root {
  background-color: rgb(244, 248, 255);
}

.about,
.contact,
.blog,
.shop,
.gallery,
.services {
  justify-content: center;
  align-items: center;
  /* font-size: 4rem; */
  background-position: center;
  background-repeat: none;
  background-size: cover;
  
}
.title {
  color:rgb(20, 50, 54);
  font-size: 2vw;
  font-family: 'Playfair Display', serif;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
@media(max-width:768px) {
 .title {
  font-size: 18px;  
 }
}
/* smth */
.home {
  background-image: url(./images/1.png);
  height:auto;
}
.logo {
  /* background-image: url(./images/logo.png); */
  background-repeat: no-repeat;
  background-position: center;
  margin-top:2rem;
}

#services {
  background-color: #232a3a;
 
}
/* .golden-text {
  font-size: 42px;
} */
/* #root {
  background-color: #f8f0ec;
}
#root .services {
  background-color: #fff1e6;
}

#root .about {
  background-color: #f0efeb;
}
#root .blog {
  background-color: #e9ecef;
}
#root .gallery {
  background-color: #d8f3dc;
} */
h1 {
  align-items: center;
  display: flex;
  justify-content: center;
  
}
