.navbar-container {
  position: sticky;
  top: 0;
  z-index: 2;
  /* overflow: hidden; */
  padding-top: 2rem;
}

.navbar {
  background-color: transparent;
  width: 100%;
  height: 80px;
  display: flex;
  position: -webkit-sticky;
  margin-top: -2rem;
  font-size: 1rem;
  font-family: "Playfair Display", serif;
}
.navbar.active {
  color: #fff;
  background-color: rgba(24, 150, 150, 0.9);
}

.nav-items {
  width: 65vw;
  display: flex;
  justify-content: flex-center;
  /* margin-right: 2rem; */
}

.nav-item {
  list-style: none;
  margin-left: 2rem;
}

.nav-item a {
  text-decoration: none;
  color: rgb(20, 63, 63);
  font-size: 1.2rem;
  padding: 6px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.2s all ease-out;
}

.nav-item a:hover {
  background: rgba(247, 239, 244, 0.5);
  color: rgb(23, 165, 165);
}

.nav-item a span {
  margin-left: 0.6rem;
  font-size: 1.3rem;
}

.sidebar-toggle {
  color: rgb(41, 68, 70);
  width: 65vw;
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}

.sidebar {
  width: 16rem;
  background: rgba(146, 221, 226, 0.8);
  height: 100vh;
  position: absolute;
  left: -100%;
  transition: 0.3s all ease-in-out;
}

.sidebar.active {
  left: 0;
}

.sidebar-items {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: 0.2s all ease-out;
}

.sidebar-item {
  list-style: none;
  margin-top: 1rem;
  width: 100%;
  height: 3rem;
}

.sidebar-item a {
  text-decoration: none;
  color: rgb(8, 59, 61);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s all ease-out;
}

.sidebar-item a span {
  margin-left: 1.5rem;
  font-size: 1.4rem;
}

.sidebar-item a:hover {
  background: rgb(23, 165, 165);
  color: rgb(227, 230, 230);
}

.sidebar .btn {
  margin-top: 2.4rem;
  width: 100%;
}

@media (min-width: 768px) {
  .sidebar-toggle-logo {
    font-size: 1rem;
    right: 8%;
    top: 25px;
  }
}
@media screen and (max-width: 900px) {
  .sidebar.active {
    width: 70%;
  }

  .sidebar-item a span {
    display: block;
  }
}
@media (min-width: 1024px) {
  .nav-items {
    margin-right: 10%;
  }
  .nav-item a {
    width: 6rem;
    height: 4rem;
  }

  .navbar-logo {
    font-size: 100%;
    width: 9rem;
  }
  .nav-item a span {
    margin-left: 0.6rem;
    font-size: 1rem;
  }
}
@media (min-width: 1440px) {
  .navbar-logo {
    font-size: 150%;
    width: 9rem;
  }
  .nav-items {
    margin-right: 10%;
  }
  .nav-item a {
    width: 8rem;
    font-size: 2rem;
  }
  .nav-item a span {
    margin-left: 0.6rem;
    font-size: 1.5rem;
  }
}

@media (min-width: 2560px) {
  .navbar-logo {
    font-size: 250%;
  }
  .nav-items {
    margin-right: 10%;
  }
  .nav-item a {
    width: 12rem;
    font-size: 2rem;
  }
  .nav-item a span {
    margin-left: 0.6rem;
    font-size: 2.2rem;
  }
}
