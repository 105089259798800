.homeImages-container {
    width: 90%;
    height:auto;
    margin: auto;
    padding-bottom: 2rem;
    padding-top: 2rem;
}

 .home-title {
  height:80px;
   font-size: 14px;
   font-weight: 300;
  
 }

 .img-cont { 
  
   width:100%;
   height: auto;
   
 }
.home-images {
  width:100%;
  height: auto;
  margin: auto;
  
}