
.slider-container  {
 
  width: 90%;
  height: auto;
  margin: auto;
  background-color: none;
  padding-bottom: 2rem;
  padding-top:2rem;
 
 
}
.image-slider  {
  
  width: 100%;
  height: auto;
}
.img-y {
 
  width: 70%;
  max-height: 300px;
  margin: auto;
  object-fit: contain;
}

