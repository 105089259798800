.massage-home {
    background-color: none;
    width: 90%;
    height:auto;
    margin: auto; 
    margin-bottom: 2rem;
    background-image: url(../images/bub.png);
    background-size:contain;
    background-position: right;
    background-repeat: no-repeat;
    background-color: rgb(10, 151, 151, 0.2);
    
}
.why {
    
    background-color: rgb(244, 248, 255);
}

.picsall {
    transition: box-shadow .3s;
    width: 100%;
    height: auto;
     min-height: 180px; 
    font-size:100%;
    margin: 2px;
    text-transform: capitalize;
  
}
.picsall:hover {
    box-shadow: 0 0 11px rgba(10, 151, 151,.4); 
  }
.pics {
    width:100%;
    height: auto;
    float:left;
 
}
.pics1 { 
    background-image: url(../images/pic1.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    width:60%;
    height: 100%;
    
}
.pics2 {
    background-image: url(../images/pic2.jpg);
    
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    width:60%;
    height: 100%;
    
}
.pics3 {
    background-image: url(../images/pic3.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    width:60%;
    height: 100%;
    
}
.pics4 {
    background-image: url(../images/pic4.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    width:60%;
    height: 100%;
     
}
.content-pics {
    vertical-align: middle;
}
.middle {
    padding-top: 2rem;
}
